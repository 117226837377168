var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c(
        "div",
        [
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "12", md: "8" } }, [
                _c("p", [
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(
                        this.FormMSG(
                          121,
                          "Plan in advance the practical and streamlined application of the sustainability criteria to be adopted, using simple information and concrete, in order to minimize the impact on the environment, without slowing down the work of the team."
                        )
                      ) +
                      "\n\t\t\t\t"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(
                        this.FormMSG(
                          122,
                          "Plan the mobility of production in advance in order to optimize transport and thus reduce its environmental impact and economic."
                        )
                      ) +
                      "\n\t\t\t\t"
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12", md: "8" } },
                [
                  _vm.GF
                    ? _c(
                        "b-card",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { id: "form-step-1", lg: "12" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "clearfix mb-4" },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          directives: [
                                            {
                                              name: "b-modal",
                                              rawName:
                                                "v-b-modal.modal-gfhelp-1",
                                              modifiers: {
                                                "modal-gfhelp-1": true,
                                              },
                                            },
                                          ],
                                          staticClass:
                                            "pull-right rounded-pill",
                                          attrs: {
                                            variant: "dark",
                                            size: "sm",
                                          },
                                        },
                                        [
                                          _c("i", { staticClass: "icon-info" }),
                                          _vm._v(
                                            " " +
                                              _vm._s(this.FormMSG(7, "Help"))
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "h2",
                                        { staticClass: "text-primary h4" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.FormMSG(
                                                3,
                                                "Mandatory Prerequisite"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-modal",
                                        {
                                          attrs: {
                                            "header-class":
                                              "header-class-modal-doc-package",
                                            id: "modal-gfhelp-1",
                                            size: "lg",
                                            "hide-header": "",
                                            "hide-footer": "",
                                            "modal-class": "mui-animation",
                                            fade: false,
                                          },
                                        },
                                        [
                                          _c("h3", { staticClass: "mb-3" }, [
                                            _vm._v(
                                              _vm._s(
                                                this.FormMSG(
                                                  3,
                                                  "Mandatory Prerequisite"
                                                )
                                              )
                                            ),
                                          ]),
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                this.FormMSG(
                                                  103,
                                                  "Mandatory Prerequisite Help text"
                                                )
                                              ),
                                            },
                                          }),
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                this.FormMSG(
                                                  1031,
                                                  "Mandatory Prerequisite Help text PR1"
                                                )
                                              ),
                                            },
                                          }),
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                this.FormMSG(
                                                  1032,
                                                  "Mandatory Prerequisite Help text PR2"
                                                )
                                              ),
                                            },
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "clearfix mt-3 pt-3 border-top",
                                            },
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  staticClass: "pull-right",
                                                  attrs: {
                                                    variant: "secondary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.$bvModal.hide(
                                                        "modal-gfhelp-1"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      this.FormMSG(
                                                        10,
                                                        "Thank you !"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "border-top" },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "p-3",
                                          attrs: { cols: "12", md: "8" },
                                        },
                                        [
                                          _c(
                                            "strong",
                                            { staticClass: "text-secondary" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    this.FormMSG(
                                                      4,
                                                      "PR1 - Sustainable development plan"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c("br"),
                                          _c(
                                            "span",
                                            { staticClass: "text-info" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.GF.numDocPr1) +
                                                  " " +
                                                  _vm._s(
                                                    this.FormMSG(
                                                      70,
                                                      "document(s) uploaded"
                                                    )
                                                  )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "p-3",
                                          attrs: { cols: "12", md: "4" },
                                        },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              directives: [
                                                {
                                                  name: "b-modal",
                                                  rawName:
                                                    "v-b-modal.modal-gfdocs-1",
                                                  modifiers: {
                                                    "modal-gfdocs-1": true,
                                                  },
                                                },
                                              ],
                                              attrs: {
                                                variant: "light",
                                                block: "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  this.FormMSG(
                                                    5,
                                                    "Manage documents"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "border-top" },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "p-3",
                                          attrs: { cols: "12", md: "8" },
                                        },
                                        [
                                          _c(
                                            "strong",
                                            { staticClass: "text-secondary" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    this.FormMSG(
                                                      6,
                                                      "PR2 - Transport optimization plan"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c("br"),
                                          _c(
                                            "span",
                                            { staticClass: "text-info" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.GF.numDocPr2) +
                                                  " " +
                                                  _vm._s(
                                                    this.FormMSG(
                                                      70,
                                                      "document(s) uploaded"
                                                    )
                                                  )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "p-3",
                                          attrs: { cols: "12", md: "4" },
                                        },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              directives: [
                                                {
                                                  name: "b-modal",
                                                  rawName:
                                                    "v-b-modal.modal-gfdocs-2",
                                                  modifiers: {
                                                    "modal-gfdocs-2": true,
                                                  },
                                                },
                                              ],
                                              attrs: {
                                                variant: "light",
                                                block: "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  this.FormMSG(
                                                    5,
                                                    "Manage documents"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-modal",
                    {
                      ref: "modal1",
                      attrs: {
                        "header-class": "header-class-modal-doc-package",
                        id: "modal-gfdocs-1",
                        size: "lg",
                        title: this.FormMSG(
                          4,
                          "PR1 - Sustainable development plan"
                        ),
                        "hide-footer": "",
                        "modal-class": "mui-animation",
                        fade: false,
                      },
                    },
                    [
                      _c("GreenFilmDocs", {
                        attrs: {
                          GFId: _vm.GF.id,
                          GFType: "greenfilm",
                          GFSubType: "PR1",
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "clearfix mt-3 pt-3 border-top" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "pull-right",
                              attrs: { variant: "secondary" },
                              on: { click: _vm.closeModal1 },
                            },
                            [_vm._v(_vm._s(this.FormMSG(64, "Close window")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-modal",
                    {
                      ref: "modal2",
                      attrs: {
                        "header-class": "header-class-modal-doc-package",
                        id: "modal-gfdocs-2",
                        size: "lg",
                        title: this.FormMSG(
                          6,
                          "PR2 - Transport optimization plan"
                        ),
                        "hide-footer": "",
                        "modal-class": "mui-animation",
                        fade: false,
                      },
                    },
                    [
                      _c("GreenFilmDocs", {
                        attrs: {
                          GFId: _vm.GF.id,
                          GFType: "greenfilm",
                          GFSubType: "PR2",
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "clearfix mt-3 pt-3 border-top" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "pull-right",
                              attrs: { variant: "secondary" },
                              on: { click: _vm.closeModal2 },
                            },
                            [_vm._v(_vm._s(this.FormMSG(64, "Close window")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.GF
                    ? _c(
                        "b-card",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { id: "form-step-2", lg: "12" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "clearfix mb-4" },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          directives: [
                                            {
                                              name: "b-modal",
                                              rawName:
                                                "v-b-modal.modal-gfhelp-2",
                                              modifiers: {
                                                "modal-gfhelp-2": true,
                                              },
                                            },
                                          ],
                                          staticClass:
                                            "pull-right rounded-pill",
                                          attrs: {
                                            variant: "dark",
                                            size: "sm",
                                          },
                                        },
                                        [
                                          _c("i", { staticClass: "icon-info" }),
                                          _vm._v(
                                            " " +
                                              _vm._s(this.FormMSG(7, "Help"))
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "h2",
                                        { staticClass: "text-primary h4" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.FormMSG(11, "Energy savings")
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-modal",
                                        {
                                          attrs: {
                                            "header-class":
                                              "header-class-modal-doc-package",
                                            id: "modal-gfhelp-2",
                                            size: "lg",
                                            "hide-header": "",
                                            "hide-footer": "",
                                            "modal-class": "mui-animation",
                                            fade: false,
                                          },
                                        },
                                        [
                                          _c("h3", { staticClass: "mb-3" }, [
                                            _vm._v(
                                              _vm._s(
                                                this.FormMSG(
                                                  111,
                                                  "A. Energy savings"
                                                )
                                              )
                                            ),
                                          ]),
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                this.FormMSG(
                                                  1110,
                                                  "Energy savings help text"
                                                )
                                              ),
                                            },
                                          }),
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                this.FormMSG(
                                                  1111,
                                                  "Energy savings Help text A1"
                                                )
                                              ),
                                            },
                                          }),
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                this.FormMSG(
                                                  1112,
                                                  "Energy savings Help text A2"
                                                )
                                              ),
                                            },
                                          }),
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                this.FormMSG(
                                                  1113,
                                                  "Energy savings Help text A3"
                                                )
                                              ),
                                            },
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "clearfix mt-3 pt-3 border-top",
                                            },
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  staticClass: "pull-right",
                                                  attrs: {
                                                    variant: "secondary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.$bvModal.hide(
                                                        "modal-gfhelp-2"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      this.FormMSG(
                                                        10,
                                                        "Thank you !"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "border-top" },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "p-3",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              staticClass: "m-0",
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  12,
                                                  "A1 - Connection to local electricity network"
                                                ),
                                                "label-for": "A1",
                                                "label-cols": 11,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  attrs: {
                                                    size: "md",
                                                    id: "A1",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.saveInformation()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.GF
                                                        .a1ElectricityFromLocalNetwork,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.GF,
                                                        "a1ElectricityFromLocalNetwork",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "GF.a1ElectricityFromLocalNetwork",
                                                  },
                                                },
                                                [
                                                  _vm.GF
                                                    .a1ElectricityFromLocalNetwork
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              60,
                                                              "Yes"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              61,
                                                              "No"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "border-top" },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "p-3",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              staticClass: "m-0",
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  62,
                                                  "A2 - Green energy"
                                                ),
                                                "label-for": "A2",
                                                "label-cols": 11,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  attrs: {
                                                    size: "md",
                                                    id: "A2",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.saveInformation()
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.GF.a2GreenEnergy,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.GF,
                                                        "a2GreenEnergy",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "GF.a2GreenEnergy",
                                                  },
                                                },
                                                [
                                                  _vm.GF.a2GreenEnergy
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              60,
                                                              "Yes"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              61,
                                                              "No"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "border-top" },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "p-3",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              staticClass: "m-0",
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  14,
                                                  "A3 - Led lighting"
                                                ),
                                                "label-for": "A3",
                                                "label-cols": 11,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  attrs: {
                                                    size: "md",
                                                    id: "A3",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.saveInformation()
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.GF.a3LedLighting,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.GF,
                                                        "a3LedLighting",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "GF.a3LedLighting",
                                                  },
                                                },
                                                [
                                                  _vm.GF.a3LedLighting
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              60,
                                                              "Yes"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              61,
                                                              "No"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("loading", {
                                    attrs: {
                                      active: _vm.isLoading,
                                      "is-full-page": true,
                                    },
                                    on: {
                                      "update:active": function ($event) {
                                        _vm.isLoading = $event
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.GF
                    ? _c(
                        "b-card",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { id: "form-step-3", lg: "12" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "clearfix mb-4" },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          directives: [
                                            {
                                              name: "b-modal",
                                              rawName:
                                                "v-b-modal.modal-gfhelp-3",
                                              modifiers: {
                                                "modal-gfhelp-3": true,
                                              },
                                            },
                                          ],
                                          staticClass:
                                            "pull-right rounded-pill",
                                          attrs: {
                                            variant: "dark",
                                            size: "sm",
                                          },
                                        },
                                        [
                                          _c("i", { staticClass: "icon-info" }),
                                          _vm._v(
                                            " " +
                                              _vm._s(this.FormMSG(7, "Help"))
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "h2",
                                        { staticClass: "text-primary h4" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.FormMSG(
                                                19,
                                                "Transport and accomodations"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-modal",
                                        {
                                          attrs: {
                                            "header-class":
                                              "header-class-modal-doc-package",
                                            id: "modal-gfhelp-3",
                                            size: "lg",
                                            "hide-header": "",
                                            "hide-footer": "",
                                            "modal-class": "mui-animation",
                                            fade: false,
                                          },
                                        },
                                        [
                                          _c("h3", { staticClass: "mb-3" }, [
                                            _vm._v(
                                              _vm._s(
                                                this.FormMSG(
                                                  119,
                                                  "B. Transport and accomodations"
                                                )
                                              )
                                            ),
                                          ]),
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                this.FormMSG(
                                                  1190,
                                                  "Transport and accomodations Help  text"
                                                )
                                              ),
                                            },
                                          }),
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                this.FormMSG(
                                                  1191,
                                                  "Transport and accomodations Help B1  text"
                                                )
                                              ),
                                            },
                                          }),
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                this.FormMSG(
                                                  1192,
                                                  "Transport and accomodations Help B2 text"
                                                )
                                              ),
                                            },
                                          }),
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                this.FormMSG(
                                                  1193,
                                                  "Transport and accomodations Help B3 text"
                                                )
                                              ),
                                            },
                                          }),
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                this.FormMSG(
                                                  1194,
                                                  "Transport and accomodations Help B4 text"
                                                )
                                              ),
                                            },
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "clearfix mt-3 pt-3 border-top",
                                            },
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  staticClass: "pull-right",
                                                  attrs: {
                                                    variant: "secondary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.$bvModal.hide(
                                                        "modal-gfhelp-3"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      this.FormMSG(
                                                        10,
                                                        "Thank you !"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "border-top" },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "p-3",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              staticClass: "m-0",
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  22,
                                                  "B1 - Vehicules EURO 5"
                                                ),
                                                "label-for": "B1",
                                                "label-cols": 11,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  attrs: {
                                                    size: "md",
                                                    id: "B1",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.saveInformation()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.GF.b1VehiculeEuro5,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.GF,
                                                        "b1VehiculeEuro5",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "GF.b1VehiculeEuro5",
                                                  },
                                                },
                                                [
                                                  _vm.GF.b1VehiculeEuro5
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              60,
                                                              "Yes"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              61,
                                                              "No"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "border-top" },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "p-3",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              staticClass: "m-0",
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  23,
                                                  "B2 - Vehicules Green"
                                                ),
                                                "label-for": "B2",
                                                "label-cols": 11,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  attrs: {
                                                    size: "md",
                                                    id: "B2",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.saveInformation()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.GF.b2VehiculeGreen,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.GF,
                                                        "b2VehiculeGreen",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "GF.b2VehiculeGreen",
                                                  },
                                                },
                                                [
                                                  _vm.GF.b2VehiculeGreen
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              60,
                                                              "Yes"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              61,
                                                              "No"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "border-top" },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "p-3",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              staticClass: "m-0",
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  24,
                                                  "B3 - Accomodation close by"
                                                ),
                                                "label-for": "B3",
                                                "label-cols": 11,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  attrs: {
                                                    size: "md",
                                                    id: "B3",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.saveInformation()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.GF
                                                        .b3AccomodationCloseBy,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.GF,
                                                        "b3AccomodationCloseBy",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "GF.b3AccomodationCloseBy",
                                                  },
                                                },
                                                [
                                                  _vm.GF.b3AccomodationCloseBy
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              60,
                                                              "Yes"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              61,
                                                              "No"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "border-top" },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "p-3",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              staticClass: "m-0",
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  25,
                                                  "B4 - Green label accomodation"
                                                ),
                                                "label-for": "B4",
                                                "label-cols": 11,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  attrs: {
                                                    size: "md",
                                                    id: "B4",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.saveInformation()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.GF
                                                        .b4GreenLabelAccomodation,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.GF,
                                                        "b4GreenLabelAccomodation",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "GF.b4GreenLabelAccomodation",
                                                  },
                                                },
                                                [
                                                  _vm.GF
                                                    .b4GreenLabelAccomodation
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              60,
                                                              "Yes"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              61,
                                                              "No"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("loading", {
                                    attrs: {
                                      active: _vm.isLoading,
                                      "is-full-page": true,
                                    },
                                    on: {
                                      "update:active": function ($event) {
                                        _vm.isLoading = $event
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.GF
                    ? _c(
                        "b-card",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { id: "form-step-4", lg: "12" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "clearfix mb-4" },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          directives: [
                                            {
                                              name: "b-modal",
                                              rawName:
                                                "v-b-modal.modal-gfhelp-4",
                                              modifiers: {
                                                "modal-gfhelp-4": true,
                                              },
                                            },
                                          ],
                                          staticClass:
                                            "pull-right rounded-pill",
                                          attrs: {
                                            variant: "dark",
                                            size: "sm",
                                          },
                                        },
                                        [
                                          _c("i", { staticClass: "icon-info" }),
                                          _vm._v(
                                            " " +
                                              _vm._s(this.FormMSG(7, "Help"))
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "h2",
                                        { staticClass: "text-primary h4" },
                                        [
                                          _vm._v(
                                            _vm._s(this.FormMSG(26, "Catering"))
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-modal",
                                        {
                                          attrs: {
                                            "header-class":
                                              "header-class-modal-doc-package",
                                            id: "modal-gfhelp-4",
                                            size: "lg",
                                            "hide-header": "",
                                            "hide-footer": "",
                                            "modal-class": "mui-animation",
                                            fade: false,
                                          },
                                        },
                                        [
                                          _c("h3", { staticClass: "mb-3" }, [
                                            _vm._v(
                                              _vm._s(
                                                this.FormMSG(127, "C. Catering")
                                              )
                                            ),
                                          ]),
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                this.FormMSG(
                                                  1270,
                                                  "Catering Help text"
                                                )
                                              ),
                                            },
                                          }),
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                this.FormMSG(
                                                  1271,
                                                  "Catering Help text C1  text"
                                                )
                                              ),
                                            },
                                          }),
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                this.FormMSG(
                                                  1272,
                                                  "Catering Help text C2 text"
                                                )
                                              ),
                                            },
                                          }),
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                this.FormMSG(
                                                  1273,
                                                  "Catering Help text C3 text"
                                                )
                                              ),
                                            },
                                          }),
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                this.FormMSG(
                                                  1274,
                                                  "Catering Help text C4 text"
                                                )
                                              ),
                                            },
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "clearfix mt-3 pt-3 border-top",
                                            },
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  staticClass: "pull-right",
                                                  attrs: {
                                                    variant: "secondary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.$bvModal.hide(
                                                        "modal-gfhelp-4"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      this.FormMSG(
                                                        10,
                                                        "Thank you !"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "border-top" },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "p-3",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c("div", [
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  this.FormMSG(29, "Water")
                                                )
                                              ),
                                            ]),
                                          ]),
                                          _c(
                                            "b-form-group",
                                            {
                                              staticClass: "m-0",
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  30,
                                                  "C1 - Tab water"
                                                ),
                                                "label-for": "C1-1",
                                                "label-cols": 11,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  attrs: {
                                                    size: "md",
                                                    id: "C1-1",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.saveInformation()
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.GF.c1TabWater,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.GF,
                                                        "c1TabWater",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "GF.c1TabWater",
                                                  },
                                                },
                                                [
                                                  _vm.GF.c1TabWater
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              60,
                                                              "Yes"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              61,
                                                              "No"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-form-group",
                                            {
                                              staticClass: "m-0",
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  31,
                                                  "C1 - Water fontain"
                                                ),
                                                "label-for": "C1-2",
                                                "label-cols": 11,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  attrs: {
                                                    size: "md",
                                                    id: "C1-2",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.saveInformation()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.GF.c1WaterFontain,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.GF,
                                                        "c1WaterFontain",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "GF.c1WaterFontain",
                                                  },
                                                },
                                                [
                                                  _vm.GF.c1WaterFontain
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              60,
                                                              "Yes"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              61,
                                                              "No"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "border-top" },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "p-3",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c("div", [
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  this.FormMSG(32, "Dishes")
                                                )
                                              ),
                                            ]),
                                          ]),
                                          _c(
                                            "b-form-group",
                                            {
                                              staticClass: "m-0",
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  33,
                                                  "C2 - Catering"
                                                ),
                                                "label-for": "C2-1",
                                                "label-cols": 11,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  attrs: {
                                                    size: "md",
                                                    id: "C2-1",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.saveInformation()
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.GF.c2Catering,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.GF,
                                                        "c2Catering",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "GF.c2Catering",
                                                  },
                                                },
                                                [
                                                  _vm.GF.c2Catering
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              60,
                                                              "Yes"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              61,
                                                              "No"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-form-group",
                                            {
                                              staticClass: "m-0",
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  34,
                                                  "C2 - Restaurant"
                                                ),
                                                "label-for": "C2-2",
                                                "label-cols": 11,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  attrs: {
                                                    size: "md",
                                                    id: "C2-2",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.saveInformation()
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.GF.c2Restaurant,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.GF,
                                                        "c2Restaurant",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "GF.c2Restaurant",
                                                  },
                                                },
                                                [
                                                  _vm.GF.c2Restaurant
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              60,
                                                              "Yes"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              61,
                                                              "No"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "border-top" },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "p-3",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              staticClass: "m-0",
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  35,
                                                  "C3 - Reusable dishes"
                                                ),
                                                "label-for": "C3",
                                                "label-cols": 11,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  attrs: {
                                                    size: "md",
                                                    id: "C3",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.saveInformation()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.GF.c3ReusableDishes,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.GF,
                                                        "c3ReusableDishes",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "GF.c3ReusableDishes",
                                                  },
                                                },
                                                [
                                                  _vm.GF.c3ReusableDishes
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              60,
                                                              "Yes"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              61,
                                                              "No"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "border-top" },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "p-3",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c("div", [
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(this.FormMSG(36, "Cup"))
                                              ),
                                            ]),
                                          ]),
                                          _c(
                                            "b-form-group",
                                            {
                                              staticClass: "m-0",
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  37,
                                                  "C4 - Washable cup"
                                                ),
                                                "label-for": "C4-1",
                                                "label-cols": 11,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  attrs: {
                                                    size: "md",
                                                    id: "C4-1",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.saveInformation()
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.GF.c4WashableCup,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.GF,
                                                        "c4WashableCup",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "GF.c4WashableCup",
                                                  },
                                                },
                                                [
                                                  _vm.GF.c4WashableCup
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              60,
                                                              "Yes"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              61,
                                                              "No"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-form-group",
                                            {
                                              staticClass: "m-0",
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  38,
                                                  "C4 - Recyclable cup"
                                                ),
                                                "label-for": "C4-2",
                                                "label-cols": 11,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  attrs: {
                                                    size: "md",
                                                    id: "C4-2",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.saveInformation()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.GF.c4RecyclableCup,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.GF,
                                                        "c4RecyclableCup",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "GF.c4RecyclableCup",
                                                  },
                                                },
                                                [
                                                  _vm.GF.c4RecyclableCup
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              60,
                                                              "Yes"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              61,
                                                              "No"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("loading", {
                                    attrs: {
                                      active: _vm.isLoading,
                                      "is-full-page": true,
                                    },
                                    on: {
                                      "update:active": function ($event) {
                                        _vm.isLoading = $event
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.GF
                    ? _c(
                        "b-card",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { id: "form-step-5", lg: "12" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "clearfix mb-4" },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          directives: [
                                            {
                                              name: "b-modal",
                                              rawName:
                                                "v-b-modal.modal-gfhelp-5",
                                              modifiers: {
                                                "modal-gfhelp-5": true,
                                              },
                                            },
                                          ],
                                          staticClass:
                                            "pull-right rounded-pill",
                                          attrs: {
                                            variant: "dark",
                                            size: "sm",
                                          },
                                        },
                                        [
                                          _c("i", { staticClass: "icon-info" }),
                                          _vm._v(
                                            " " +
                                              _vm._s(this.FormMSG(7, "Help"))
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "h2",
                                        { staticClass: "text-primary h4" },
                                        [
                                          _vm._v(
                                            _vm._s(this.FormMSG(39, "Material"))
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-modal",
                                        {
                                          attrs: {
                                            "header-class":
                                              "header-class-modal-doc-package",
                                            id: "modal-gfhelp-5",
                                            size: "lg",
                                            "hide-header": "",
                                            "hide-footer": "",
                                            "modal-class": "mui-animation",
                                            fade: false,
                                          },
                                        },
                                        [
                                          _c("h3", { staticClass: "mb-3" }, [
                                            _vm._v(
                                              _vm._s(
                                                this.FormMSG(139, "D. Material")
                                              )
                                            ),
                                          ]),
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                this.FormMSG(
                                                  1390,
                                                  "Material Help  text"
                                                )
                                              ),
                                            },
                                          }),
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                this.FormMSG(
                                                  1391,
                                                  "Material Help text D1  text"
                                                )
                                              ),
                                            },
                                          }),
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                this.FormMSG(
                                                  1392,
                                                  "Material Help text D2 text"
                                                )
                                              ),
                                            },
                                          }),
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                this.FormMSG(
                                                  1393,
                                                  "Material Help text D3 text"
                                                )
                                              ),
                                            },
                                          }),
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                this.FormMSG(
                                                  1394,
                                                  "Material Help text D4 text"
                                                )
                                              ),
                                            },
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "clearfix mt-3 pt-3 border-top",
                                            },
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  staticClass: "pull-right",
                                                  attrs: {
                                                    variant: "secondary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.$bvModal.hide(
                                                        "modal-gfhelp-5"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      this.FormMSG(
                                                        10,
                                                        "Thank you !"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "border-top" },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "p-3",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c("div", [
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  this.FormMSG(
                                                    42,
                                                    "Material supplier"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]),
                                          _c(
                                            "b-form-group",
                                            {
                                              staticClass: "m-0",
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  43,
                                                  "D1 - Material supplier fsc"
                                                ),
                                                "label-for": "D1-1",
                                                "label-cols": 11,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  attrs: {
                                                    size: "md",
                                                    id: "D1-1",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.saveInformation()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.GF
                                                        .d1MaterialSupplierFsc,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.GF,
                                                        "d1MaterialSupplierFsc",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "GF.d1MaterialSupplierFsc",
                                                  },
                                                },
                                                [
                                                  _vm.GF.d1MaterialSupplierFsc
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              60,
                                                              "Yes"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              61,
                                                              "No"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-form-group",
                                            {
                                              staticClass: "m-0",
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  44,
                                                  "D1 - Materiel supplier emas"
                                                ),
                                                "label-for": "D1-2",
                                                "label-cols": 11,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  attrs: {
                                                    size: "md",
                                                    id: "D1-2",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.saveInformation()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.GF
                                                        .d1MaterialSupplierEmas,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.GF,
                                                        "d1MaterialSupplierEmas",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "GF.d1MaterialSupplierEmas",
                                                  },
                                                },
                                                [
                                                  _vm.GF.d1MaterialSupplierEmas
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              60,
                                                              "Yes"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              61,
                                                              "No"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "border-top" },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "p-3",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              staticClass: "m-0",
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  45,
                                                  "D2 - Material recycled"
                                                ),
                                                "label-for": "D2",
                                                "label-cols": 11,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  attrs: {
                                                    size: "md",
                                                    id: "D2",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.saveInformation()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.GF.d2MaterialRecycled,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.GF,
                                                        "d2MaterialRecycled",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "GF.d2MaterialRecycled",
                                                  },
                                                },
                                                [
                                                  _vm.GF.d2MaterialRecycled
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              60,
                                                              "Yes"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              61,
                                                              "No"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "border-top" },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "p-3",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              staticClass: "m-0",
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  46,
                                                  "D3 - Reusable stage material"
                                                ),
                                                "label-for": "D3",
                                                "label-cols": 11,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  attrs: {
                                                    size: "md",
                                                    id: "D3",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.saveInformation()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.GF
                                                        .d3ReusableStageMaterial,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.GF,
                                                        "d3ReusableStageMaterial",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "GF.d3ReusableStageMaterial",
                                                  },
                                                },
                                                [
                                                  _vm.GF.d3ReusableStageMaterial
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              60,
                                                              "Yes"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              61,
                                                              "No"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "border-top" },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "p-3",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              staticClass: "m-0",
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  47,
                                                  "D4 - Electronic info exchange"
                                                ),
                                                "label-for": "D4",
                                                "label-cols": 11,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  attrs: {
                                                    size: "md",
                                                    id: "D4",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.saveInformation()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.GF
                                                        .d4ElectronicInfoExchange,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.GF,
                                                        "d4ElectronicInfoExchange",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "GF.d4ElectronicInfoExchange",
                                                  },
                                                },
                                                [
                                                  _vm.GF
                                                    .d4ElectronicInfoExchange
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              60,
                                                              "Yes"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              61,
                                                              "No"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("loading", {
                                    attrs: {
                                      active: _vm.isLoading,
                                      "is-full-page": true,
                                    },
                                    on: {
                                      "update:active": function ($event) {
                                        _vm.isLoading = $event
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.GF
                    ? _c(
                        "b-card",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { id: "form-step-6", lg: "12" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "clearfix mb-4" },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          directives: [
                                            {
                                              name: "b-modal",
                                              rawName:
                                                "v-b-modal.modal-gfhelp-6",
                                              modifiers: {
                                                "modal-gfhelp-6": true,
                                              },
                                            },
                                          ],
                                          staticClass:
                                            "pull-right rounded-pill",
                                          attrs: {
                                            variant: "dark",
                                            size: "sm",
                                          },
                                        },
                                        [
                                          _c("i", { staticClass: "icon-info" }),
                                          _vm._v(
                                            " " +
                                              _vm._s(this.FormMSG(7, "Help"))
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "h2",
                                        { staticClass: "text-primary h4" },
                                        [
                                          _vm._v(
                                            _vm._s(this.FormMSG(48, "Waste"))
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-modal",
                                        {
                                          attrs: {
                                            "header-class":
                                              "header-class-modal-doc-package",
                                            id: "modal-gfhelp-6",
                                            size: "lg",
                                            "hide-header": "",
                                            "hide-footer": "",
                                            "modal-class": "mui-animation",
                                            fade: false,
                                          },
                                        },
                                        [
                                          _c("h3", { staticClass: "mb-3" }, [
                                            _vm._v(
                                              _vm._s(
                                                this.FormMSG(148, "E. Waste")
                                              )
                                            ),
                                          ]),
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                this.FormMSG(
                                                  1480,
                                                  "Waste Help text"
                                                )
                                              ),
                                            },
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "clearfix mt-3 pt-3 border-top",
                                            },
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  staticClass: "pull-right",
                                                  attrs: {
                                                    variant: "secondary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.$bvModal.hide(
                                                        "modal-gfhelp-6"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      this.FormMSG(
                                                        10,
                                                        "Thank you !"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "border-top" },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "p-3",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              staticClass: "m-0",
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  51,
                                                  "E1 - Sort waste"
                                                ),
                                                "label-for": "E1",
                                                "label-cols": 11,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  attrs: {
                                                    size: "md",
                                                    id: "E1",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.saveInformation()
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.GF.e1SortWaste,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.GF,
                                                        "e1SortWaste",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "GF.e1SortWaste",
                                                  },
                                                },
                                                [
                                                  _vm.GF.e1SortWaste
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              60,
                                                              "Yes"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              61,
                                                              "No"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("loading", {
                                    attrs: {
                                      active: _vm.isLoading,
                                      "is-full-page": true,
                                    },
                                    on: {
                                      "update:active": function ($event) {
                                        _vm.isLoading = $event
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.GF
                    ? _c(
                        "b-card",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { id: "form-step-7", lg: "12" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "clearfix mb-4" },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          directives: [
                                            {
                                              name: "b-modal",
                                              rawName:
                                                "v-b-modal.modal-gfhelp-7",
                                              modifiers: {
                                                "modal-gfhelp-7": true,
                                              },
                                            },
                                          ],
                                          staticClass:
                                            "pull-right rounded-pill",
                                          attrs: {
                                            variant: "dark",
                                            size: "sm",
                                          },
                                        },
                                        [
                                          _c("i", { staticClass: "icon-info" }),
                                          _vm._v(
                                            " " +
                                              _vm._s(this.FormMSG(7, "Help"))
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "h2",
                                        { staticClass: "text-primary h4" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.FormMSG(52, "Communication")
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-modal",
                                        {
                                          attrs: {
                                            "header-class":
                                              "header-class-modal-doc-package",
                                            id: "modal-gfhelp-7",
                                            size: "lg",
                                            "hide-header": "",
                                            "hide-footer": "",
                                            "modal-class": "mui-animation",
                                            fade: false,
                                          },
                                        },
                                        [
                                          _c("h3", { staticClass: "mb-3" }, [
                                            _vm._v(
                                              _vm._s(
                                                this.FormMSG(
                                                  152,
                                                  "F. Communication"
                                                )
                                              )
                                            ),
                                          ]),
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                this.FormMSG(
                                                  1520,
                                                  "Communication Help text"
                                                )
                                              ),
                                            },
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "clearfix mt-3 pt-3 border-top",
                                            },
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  staticClass: "pull-right",
                                                  attrs: {
                                                    variant: "secondary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.$bvModal.hide(
                                                        "modal-gfhelp-7"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      this.FormMSG(
                                                        10,
                                                        "Thank you !"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "border-top" },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "p-3",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              staticClass: "m-0",
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  55,
                                                  "F1 - Communication press file"
                                                ),
                                                "label-for": "F1",
                                                "label-cols": 11,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  attrs: {
                                                    size: "md",
                                                    id: "F1",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.saveInformation()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.GF.f1ComPressFile,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.GF,
                                                        "f1ComPressFile",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "GF.f1ComPressFile",
                                                  },
                                                },
                                                [
                                                  _vm.GF.f1ComPressFile
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              60,
                                                              "Yes"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              61,
                                                              "No"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "border-top" },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "p-3",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              staticClass: "m-0",
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  56,
                                                  "F2 - Communication clip"
                                                ),
                                                "label-for": "F2",
                                                "label-cols": 11,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  attrs: {
                                                    size: "md",
                                                    id: "F2",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.saveInformation()
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.GF.f2ComClip,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.GF,
                                                        "f2ComClip",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "GF.f2ComClip",
                                                  },
                                                },
                                                [
                                                  _vm.GF.f2ComClip
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              60,
                                                              "Yes"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              61,
                                                              "No"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "border-top" },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "p-3",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              staticClass: "m-0",
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  57,
                                                  "F3 - Communication from production"
                                                ),
                                                "label-for": "F3",
                                                "label-cols": 11,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  attrs: {
                                                    size: "md",
                                                    id: "F3",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.saveInformation()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.GF
                                                        .f3ComFromProduction,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.GF,
                                                        "f3ComFromProduction",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "GF.f3ComFromProduction",
                                                  },
                                                },
                                                [
                                                  _vm.GF.f3ComFromProduction
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              60,
                                                              "Yes"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              61,
                                                              "No"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm.GF.f3ComFromProduction
                                            ? _c(
                                                "b-form-group",
                                                {
                                                  staticClass: "m-0",
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      58,
                                                      "Description"
                                                    ),
                                                    "label-for":
                                                      "F3-description",
                                                    "label-cols": 12,
                                                  },
                                                },
                                                [
                                                  _c("b-form-textarea", {
                                                    attrs: {
                                                      id: "F3-description",
                                                      placeholder: _vm.FormMSG(
                                                        59,
                                                        "Enter description..."
                                                      ),
                                                      rows: "3",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("loading", {
                                    attrs: {
                                      active: _vm.isLoading,
                                      "is-full-page": true,
                                    },
                                    on: {
                                      "update:active": function ($event) {
                                        _vm.isLoading = $event
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "order-2 d-none d-lg-block",
                  attrs: { cols: "12", md: "4" },
                },
                [
                  _c("div", { staticClass: "sidenav-sticky sticky-top pb-4" }, [
                    _vm.GF
                      ? _c("div", { staticClass: "card" }, [
                          _c("div", { staticClass: "card-body" }, [
                            _c("nav", [
                              _c("ul", { staticClass: "nav flex-column" }, [
                                _c("li", { staticClass: "nav-item" }, [
                                  _c(
                                    "a",
                                    {
                                      directives: [
                                        {
                                          name: "scroll-to",
                                          rawName: "v-scroll-to",
                                          value: {
                                            el: "#form-step-1",
                                            offset: -88,
                                          },
                                          expression:
                                            "{ el: '#form-step-1', offset: -88 }",
                                        },
                                      ],
                                      staticClass: "nav-link",
                                      attrs: { href: "#" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          this.FormMSG(
                                            3,
                                            "Mandatory Prerequisite"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("li", { staticClass: "nav-item" }, [
                                  _c(
                                    "a",
                                    {
                                      directives: [
                                        {
                                          name: "scroll-to",
                                          rawName: "v-scroll-to",
                                          value: {
                                            el: "#form-step-2",
                                            offset: -88,
                                          },
                                          expression:
                                            "{ el: '#form-step-2', offset: -88 }",
                                        },
                                      ],
                                      staticClass: "nav-link",
                                      attrs: { href: "#" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          this.FormMSG(11, "Energy savings")
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("li", { staticClass: "nav-item" }, [
                                  _c(
                                    "a",
                                    {
                                      directives: [
                                        {
                                          name: "scroll-to",
                                          rawName: "v-scroll-to",
                                          value: {
                                            el: "#form-step-3",
                                            offset: -88,
                                          },
                                          expression:
                                            "{ el: '#form-step-3', offset: -88 }",
                                        },
                                      ],
                                      staticClass: "nav-link",
                                      attrs: { href: "#" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          this.FormMSG(
                                            19,
                                            "Transport and accomodations"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("li", { staticClass: "nav-item" }, [
                                  _c(
                                    "a",
                                    {
                                      directives: [
                                        {
                                          name: "scroll-to",
                                          rawName: "v-scroll-to",
                                          value: {
                                            el: "#form-step-4",
                                            offset: -88,
                                          },
                                          expression:
                                            "{ el: '#form-step-4', offset: -88 }",
                                        },
                                      ],
                                      staticClass: "nav-link",
                                      attrs: { href: "#" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(this.FormMSG(26, "Catering"))
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("li", { staticClass: "nav-item" }, [
                                  _c(
                                    "a",
                                    {
                                      directives: [
                                        {
                                          name: "scroll-to",
                                          rawName: "v-scroll-to",
                                          value: {
                                            el: "#form-step-5",
                                            offset: -88,
                                          },
                                          expression:
                                            "{ el: '#form-step-5', offset: -88 }",
                                        },
                                      ],
                                      staticClass: "nav-link",
                                      attrs: { href: "#" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(this.FormMSG(39, "Material"))
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("li", { staticClass: "nav-item" }, [
                                  _c(
                                    "a",
                                    {
                                      directives: [
                                        {
                                          name: "scroll-to",
                                          rawName: "v-scroll-to",
                                          value: {
                                            el: "#form-step-6",
                                            offset: -88,
                                          },
                                          expression:
                                            "{ el: '#form-step-6', offset: -88 }",
                                        },
                                      ],
                                      staticClass: "nav-link",
                                      attrs: { href: "#" },
                                    },
                                    [_vm._v(_vm._s(this.FormMSG(48, "Waste")))]
                                  ),
                                ]),
                                _c("li", { staticClass: "nav-item" }, [
                                  _c(
                                    "a",
                                    {
                                      directives: [
                                        {
                                          name: "scroll-to",
                                          rawName: "v-scroll-to",
                                          value: {
                                            el: "#form-step-7",
                                            offset: -88,
                                          },
                                          expression:
                                            "{ el: '#form-step-7', offset: -88 }",
                                        },
                                      ],
                                      staticClass: "nav-link",
                                      attrs: { href: "#" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          this.FormMSG(52, "Communication")
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "card-footer",
                              class: { success: this.totalPts >= 20 },
                            },
                            [
                              _c("div", [
                                _c("span", { staticClass: "label" }, [
                                  _vm._v(
                                    _vm._s(
                                      this.FormMSG(
                                        202,
                                        "Prerequisite Total Points"
                                      )
                                    )
                                  ),
                                ]),
                                _c("span", { staticClass: "score" }, [
                                  _vm._v(_vm._s(this.totalPts)),
                                ]),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "card-body no-padding" }, [
                      _c(
                        "div",
                        { staticClass: "row" },
                        [
                          _c(
                            "b-col",
                            { attrs: { lg: "12" } },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    variant: "primary",
                                    block: "",
                                    size: "lg",
                                  },
                                  on: { click: _vm.downloadExport },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      this.FormMSG(
                                        201,
                                        "Download Green Film document"
                                      )
                                    ) + "\n\t\t\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }